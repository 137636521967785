jQuery(document).ready(function($) {
  // Small helper function for querySelector
  var z = function(e) { return document.querySelectorAll(e); }
  var _z = function(e) { return document.querySelector(e); }

  // Animation defaults
  var aniDuration = 500;
  var aniDurationFast = 200;
  var aniEase = 'easeIn';
  var opt1 = { delay: 0, easing: aniEase, duration: aniDuration, sequenceQueue: false };
  var opt2 = { delay: 0, easing: aniEase, duration: aniDurationFast, sequenceQueue: false };

  // Step and user flow
  steps = [];
  activeStep = 0; // Default first step is the first one
  lastStep = 0; // Default last step is the first one before loading data
  leadName = "";

  // Define base div/target
  wrapper = z('.quiz');
  counter = z('.counter');
  pageSubmit = z('.page-submit');
  pageOnboarding = z('.page-onboarding');
  pageOnboardingForm = z('.page-onboarding-form');
  pageOnboardingHeadline = z('.page-onboarding-headline');
  pageLeaving = z('.page-leaving');
  pageLeavingHeadline = z('.page-leaving-headline');
  pageLeavingHeadlineName = z('.page-leaving-headline-span-name');
  currentStep = 0;

  // Fill in the wrapper with items from data obj
  for(var step in data) {
    if(data.hasOwnProperty(step)) {
      steps.push(lastStep + 1);
      lastStep = steps[steps.length - 1];
      var counterStepNode = document.createElement("li");
      counterStepNode.className += "counter-step";
      counter[0].appendChild(counterStepNode);
      counter[0].style.display = "block";

      var stepNode = document.createElement("div");
      stepNode.className += "quiz-step";
      wrapper[0].appendChild(stepNode);
      currentStep++;
      currentColumn = 0;

      for(var item in data[step]) {
        if(data[step].hasOwnProperty(item)) {
          var columnNode = document.createElement("div");
          columnNode.className += "quiz-column";
          var itemNode = document.createElement("div");
          itemNode.className += "quiz-item";
          itemNode.setAttribute('tracking-id', data[step][item].trackingId);

          currentColumn++;

          stepNode.appendChild(columnNode);
          columnNode.appendChild(itemNode);

          var css = document.createElement("style");
          css.type = "text/css";
          css.innerHTML = '.main .quiz-step:nth-child('+(currentStep + 1)+') .quiz-column:nth-child('+currentColumn+') .quiz-item:before { background-image: url(' + data[step][item].imageSrc + ");}";
          document.body.appendChild(css);

          var itemLabelNode = document.createElement("div");
          var itemLabelTextNode = document.createTextNode(data[step][item].label);
          itemLabelNode.appendChild(itemLabelTextNode);
          itemLabelNode.className += "quiz-item-label";
          itemNode.appendChild(itemLabelNode);
        }
      }
    }
  }

  var stepsNode = z('.quiz-step');
  var columns = z('.quiz-column');
  var boxes = z('.quiz-item');

  function gcd (a, b) {
    return a/b;
  }

  function adaptLayoutFromRatio() {
    w = window.innerWidth;
    h = window.innerHeight;
    r = gcd (w, h);

    if ( r > 1.6 ) {
      for(var i = 0, length1 = stepsNode.length; i < length1; i++){
        stepsNode[i].style.height = h + "px";
        stepsNode[i].style.marginTop = 0 + "px";
      }
    }

    if ( r > 1.5 && r < 1.6 ) {
      for(var i = 0, length1 = stepsNode.length; i < length1; i++){
        stepsNode[i].style.height = h*0.9 + "px";
        stepsNode[i].style.marginTop = h*0.05 + "px";
      }
    }

    if ( r > 1.35 && r < 1.5 ) {
      for(var i = 0, length1 = stepsNode.length; i < length1; i++){
        stepsNode[i].style.height = h*0.8 + "px";
        stepsNode[i].style.marginTop = h*0.1 + "px";
      }
    }

    if ( r > 1.2 && r < 1.35 ) {
      for(var i = 0, length1 = stepsNode.length; i < length1; i++){
        stepsNode[i].style.height = h*0.7 + "px";
        stepsNode[i].style.marginTop = h*0.15 + "px";
      }
    }

    if ( r > 0.9 && r < 1.2 ) {
      for(var i = 0, length1 = stepsNode.length; i < length1; i++){
        stepsNode[i].style.height = h*0.6 + "px";
        stepsNode[i].style.marginTop = h*0.2 + "px";
      }
    }

    if ( r > 0.7 && r < 0.9 ) {
      for(var i = 0, length1 = stepsNode.length; i < length1; i++){
        stepsNode[i].style.height = h*0.5 + "px";
        stepsNode[i].style.marginTop = h*0.25 + "px";
      }
    }

    if ( r < 0.7 ) {
      for(var i = 0, length1 = stepsNode.length; i < length1; i++){
        stepsNode[i].style.height = h*0.4 + "px";
        stepsNode[i].style.marginTop = h*0.3 + "px";
      }
    }

    return true;
  }

  window.addEventListener('resize', function () {
    adaptLayoutFromRatio();
  });

  adaptLayoutFromRatio();

  function getItemsOfCurrentStep(currentStep){
    for (var i = 0; i < stepsNode.length; i++) {
      if (i == currentStep) {
        activeBoxes = stepsNode[i].querySelectorAll('.quiz-item');
        stepsNode[i].style.zIndex = 1;
      } else {
        stepsNode[i].style.zIndex = -1;
      }
    }

    return activeBoxes;
  }

  // Defines what's an even and an odd box
  function getOddItemsOfCurrentStep(currentStep){
    var itemsOfCurrentStep = getItemsOfCurrentStep(currentStep);
    var currentOddBoxes = [];

    for (var i = 0; i < itemsOfCurrentStep.length; i++) {
      if (i % 2 == 0) {
      }
      else {
        currentOddBoxes.push(itemsOfCurrentStep[i]);
      }
    }

    return currentOddBoxes;
  }

  // Defines what's an even and an odd box
  function getEvenItemsOfCurrentStep(currentStep){
    var itemsOfCurrentStep = getItemsOfCurrentStep(currentStep);
    var currentEvenBoxes = [];

    for (var i = 0; i < itemsOfCurrentStep.length; i++) {
       if (i % 2 == 0) {
          currentEvenBoxes.push(itemsOfCurrentStep[i]);
       }
       else {
       }
    }

    return currentEvenBoxes;
  }

  $.Velocity.hook(boxes, "opacity", "0"); // set start opacity css value
  $.Velocity.hook(boxes, "display", "none"); // set start translateY css value
  $.Velocity.hook(pageOnboarding, "opacity", "0"); // set start opacity css value

  boxesOdd = [];
  boxesEven = [];

  function jsAnim(activeStep){
    boxesOdd = getOddItemsOfCurrentStep(activeStep);
    boxesEven = getEvenItemsOfCurrentStep(activeStep);

    $(boxesEven).velocity("stop");
    $(boxesOdd).velocity("stop");

    $.Velocity.hook(boxesEven, "display", "block");
    $.Velocity.hook(boxesEven, "translateY", "100%");
    $.Velocity.hook(boxesOdd, "display", "block");
    $.Velocity.hook(boxesOdd, "translateY", "-100%");

    $(boxesEven).velocity({ translateY: '0%', opacity: 1 }, opt1);
    $(boxesOdd).velocity({ translateY: '0%', opacity: 1 }, opt1);
  }

  function vReverse(activeStepAtThisTime){
    var boxesOddAtThisTime = getOddItemsOfCurrentStep(activeStepAtThisTime);
    var boxesEvenAtThisTime = getEvenItemsOfCurrentStep(activeStepAtThisTime);

    $(boxesEvenAtThisTime).velocity("stop"); // Normal stop
    $(boxesOddAtThisTime).velocity("stop"); // Normal stop

    setTimeout(function(){
      $.Velocity.hook(boxesEvenAtThisTime, "display", "none"); // set start translateY css value
      $.Velocity.hook(boxesOddAtThisTime, "display", "none"); // set start translateY css value
    }, 1200);

    $(boxesEvenAtThisTime).velocity({ translateY: '-100%', opacity: 0 }, opt1);
    $(boxesOddAtThisTime).velocity({ translateY: '100%', opacity: 0 }, opt1);
  }

  function displayStep(activeStep){
    for (var i = 0; i < steps.length; i++) {
      if (activeStep == i) {
        $(".counter-step").removeClass('active');
        $(".counter-step").eq(i).addClass('active');
        break;
      }
    }
    return activeStep;
  }

  startTime = new Date();
  startMsec = startTime.getTime();
  lastCurrentTime = 0;

  function sendTracking(name, data) {
    var currentTime = new Date();
    var currentTimeMsec = currentTime.getTime();
    var elapsed = Math.abs((startMsec - currentTimeMsec) / 1000);
    if (lastCurrentTime !== 0) {
      var elapsedSinceLast = Math.abs((lastCurrentTime - currentTimeMsec) / 1000);
    }
    var request = new XMLHttpRequest();
    request.open('POST', self.location.protocol + '//' + self.location.host + '/l/' + new Date().getTime(), true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    var message = name+"="+data;
    message += "&" + "current-time-ms=" + currentTimeMsec;
    message += "&" + "elapsed-time-since-pageload-s=" + elapsed;
    if (lastCurrentTime !== 0) {
      message += "&" + "elapsed-time-since-last-interaction-s=" + elapsedSinceLast;
    }
    message += "&" + "screen-resolution=" + screen.width + "x" + screen.height;
    message += "&" + "viewport-width=" + window.innerWidth;
    message += "&" + "viewport-height=" + window.innerHeight;
    message += "&" + "browser-name=" + navigator.appName;
    message += "&" + "browser-version=" + navigator.appVersion;
    message += "&" + "cookies-enabled=" + navigator.cookieEnabled;
    message += "&" + "browser-language=" + navigator.language;
    message += "&" + "browser-online=" + navigator.onLine;
    message += "&" + "platform=" + navigator.platform;
    message += "&" + "user-agent=" + navigator.userAgent;
    request.send(message);

    lastCurrentTime = currentTime;
  }

  // Initial functions run
  pageOnboarding[0].style.zIndex = 1;
  $(pageOnboarding).velocity({ opacity: 1 }, {delay: 0, duration: 1000});
  $.Velocity.hook(pageOnboardingForm, "opacity", "1");
  $.Velocity.hook(wrapper, "opacity", "0");
  $.Velocity.hook(pageLeaving, "opacity", "0");
  $.Velocity.hook(pageLeavingHeadline, "opacity", "0");
  $.Velocity.hook(wrapper, "scale", "1");
  $.Velocity.hook(wrapper, "translateY", "0%");
  $.Velocity.hook(pageOnboardingHeadline, "opacity", "0");

  $(".quiz-item").click(function() {
    // Revert the previous animation
    var activeStepAtThisTime = activeStep;
    vReverse(activeStepAtThisTime);
    pageOnboarding[0].style.display = "none";
    sendTracking('quiz-item-tracking-id', $(this).attr('tracking-id'));

    if (activeStep === (lastStep-1)) {
      $(pageSubmit).velocity({ opacity: 1 }, {delay: 600, easing: aniEase, duration: 800});
      pageSubmit[0].style.zIndex = 1;
      $(counter).velocity({ opacity: 0 }, {delay: 0, easing: aniEase, duration: 800});
      setTimeout(function(){
        wrapper[0].style.zIndex = -1;
      }, 600);
    } else {
      // Go a step further
      activeStep++;

      setTimeout(function(){
        jsAnim(activeStep);
        displayStep(activeStep);
      }, 600);
    }
  });

  $(".quiz-item").hover(function() {
      /* Grayscale to color transition effect */
      $(this).velocity({
          tween: [0, 80]
      }, {
          easing: "ease",
          progress: function(elements, complete, remaining, start, tweenValue) {
              $(this)[0].style.filter = "grayscale("+tweenValue+"%)";
          }
      });
    }, function() {
      $(this).velocity({
          tween: [80, 0]
      }, {
          easing: "ease",
          progress: function(elements, complete, remaining, start, tweenValue) {
              $(this)[0].style.filter = "grayscale("+tweenValue+"%)";
          }
      });
  });

  window.onbeforeunload = function (e) {
    sendTracking("action", "user left the page");
  }

  $(".page-onboarding-form-submit").click(function(e) {
    e.preventDefault();
    leadName = document.getElementsByClassName('page-onboarding-form-input')[0].value;
    sendTracking("lead-first-name", leadName);
    $(pageOnboardingForm).velocity({ opacity: 0 }, {delay: 0, duration: 600});
    setTimeout(function(){
    pageOnboardingForm[0].style.display = 'none';
    }, 600);
    $(pageOnboardingHeadline).velocity({ opacity: 1, scale: 2.2, translateY: "60%"}, {delay: 400, easing: 'easeInOut', duration: 1800});
    $(pageOnboardingHeadline).velocity({ opacity: 0 }, {delay: 300, duration: 600});
    $(pageOnboarding).velocity({ opacity: 0 }, {delay: 3000, duration: 600});
    setTimeout(function(){
      wrapper[0].style.zIndex = 1;
    // }, 5600);
    }, 3600);
    // $(wrapper).velocity({ opacity: 1 }, {delay: 3600, duration: 600});
    $(wrapper).velocity({ opacity: 1 }, {delay: 1600, duration: 600});
    jsAnim(activeStep);
    displayStep(activeStep);
  });

  $(".page-submit-form-submit").click(function(e) {
    e.preventDefault();
    if (leadName == "") {
      leadName = "you";
    }
    pageLeavingHeadlineName[0].innerHTML = leadName;
    leadEmail = document.getElementsByClassName('page-submit-form-input')[0].value;
    sendTracking("lead-email", leadEmail);
    $(pageSubmit).velocity({ opacity: 0 }, {delay: 0, duration: 600});
    setTimeout(function(){
      pageSubmit[0].style.zIndex = -1;
    }, aniDuration);
    $(pageLeaving).velocity({ opacity: 1 }, {delay: 600, duration: 600});
    $(pageLeavingHeadline).velocity({ opacity: 1 }, {delay: 600, duration: 600});
    $(pageLeavingHeadline).velocity({ opacity: 0 }, {delay: 3600, duration: 600});

    // Play the video and redirect after play
    $('.page-leaving-video')[0].play();
    $('.page-leaving-video')[0].onended = function(e) {
      self.location.href = self.location.protocol + '//' + self.location.host + '/player';
    };

    // If on safari, use static image and fixed timeout
    var uagent = navigator.userAgent.toLowerCase();
    if(/safari/.test(uagent) && !/chrome/.test(uagent)) {
      $(pageLeaving).velocity({ opacity: 0 }, {delay: 2400, duration: 600});
      setTimeout(function(){
          // Send to /player by emulating a click on a link
          document.getElementById('hiddenLinkToPlayer').click();
      }, 4200);
    }
  });

});
